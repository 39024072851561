@import url('https://fonts.googleapis.com/css2?family=Baskervville+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply m-0 p-0 box-border;
  }

  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #a452f1;
    /* Tailwind's blue-500 */
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #951ddb;
    /* Tailwind's blue-600 */
  }

  /* For Firefox */
  * {
    scrollbar-width:thin;
    scrollbar-color: #8164e9 #f1f1f1;
  }

  body {
    @apply m-0 p-0 font-merriweather-regular-slab overflow-x-hidden;
  }


}

@layer utilities {
  

  .font-merriweather-regular-slab {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
  }


  .font-fraunces-slab {
    font-family: "Fraunces", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
      "SOFT" 0,
      "WONK" 0;
  }
  
}
